<template>
  <div>
    <b-modal
      v-if="selectedStockData"
      id="edit-modal"
      v-model="showEditModal"

      hide-footer
      :title="$t('Stok bilgisini düzenle')"
    >

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Row Loop -->

          <b-row><b-col md="12" /></b-row>
          <b-row

            ref="row"
          >

            <!-- Item Name -->
            <b-col
              md="12"
              xl="12"
              class="mb-1"
            >
              <b-form-group
                :label="$t('Satıcı Adı')"
                label-for="item-description"
              >
                <vue-autosuggest
                  v-model="selectedStockData.seller"
                  :suggestions="sellerSuggest.filteredProducts"
                  :input-props="sellerSuggest.inputProps"
                  class="sm-input"
                  @selected="sellerSelected($event,index)"
                  @input="productOnInputChanged"
                >
                  <template slot-scope="{suggestion}">
                    <div class="d-flex align-items-center">

                      <div class="detail ml-50">
                        <b-card-text class="mb-0">
                          {{ suggestion.item.name }}
                        </b-card-text>

                      </div>
                    </div>
                  </template>
                </vue-autosuggest>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="12"
              class="mb-1"
            >
              <b-form-group
                label="Alış Zamanı"
                label-for="start-date"
              >
                <flat-pickr
                  v-model="selectedStockData.buy_date"

                  class="form-control "
                  :config="$staticParams.flatPickrConfig"
                />

              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="12"
              class="mb-1"
            >
              <b-form-group
                :label="$t('Satıcı Stok Kodu')"
                label-for="item-description"
              >
                <b-form-input
                  id="item-description"
                  v-model="selectedStockData.seller_stock_code"

                  type="text"
                  :placeholder="$t('Satıcı Stok Kodu')"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="12"
              class="mb-1"
            >
              <b-form-group
                :label="$t('Alış Birim Fiyatı')"
                label-for="price"
              >
                <b-input-group>
                  <cleave
                    id="price"
                    v-model="selectedStockData.unit_price"

                    class="form-control sm-input"
                    right
                    :raw="false"
                    :options="priceInputConfig"
                  />
                  <b-input-group-append>
                    <b-form-select
                      v-model="selectedStockData.currency_id"

                      :options="currencyOptions"
                    />
                  </b-input-group-append>
                </b-input-group>

              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="12"
              class="mb-1"
            >
              <b-form-group
                :label="$t('Miktar')"
                label-for="unit_type"
              >
                <b-input-group>
                  <b-form-input
                    id="item-email"
                    v-model="selectedStockData.piece"

                    type="text"
                    :placeholder="$t('Miktar')"
                  />
                  <b-input-group-append>
                    <b-form-select
                      v-model="selectedStockData.unit_id"

                      :options="unitOptions"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="12"
              class="mb-1"
            >
              <b-form-group
                label-for="exchange-rate"
                :label="$t('Döviz Kuru')"
              >
                <cleave
                  id="exchange-rate"
                  v-model="selectedStockData.exchange_rate"

                  class="form-control sm-input"
                  right
                  :raw="false"
                  :options="priceInputConfig"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="12"
              class="mb-1"
            >
              <b-form-group
                label-for="rebase-price"
                :label="$t('Rebate Tutarı')"
              >
                <cleave
                  id="rebase-price"
                  v-model="selectedStockData.rebate_price"

                  class="form-control sm-input"
                  right
                  :raw="false"
                  :options="priceInputConfig"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="12"
              class="mb-1"
            >
              <b-form-group
                :label="$t('Açıklama')"
                label-for="rebase-price"
              >
                <b-form-input
                  id="rebase-price"
                  v-model="selectedStockData.description"
                  type="text"

                  :placeholder="$t('Açıklama')"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              xl="12"

              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="primary"

                @click="saveData()"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-25"
                />
                <span>{{ $t('Kaydet') }}</span>
              </b-button>
            </b-col>

          </b-row>

        </b-form>

      </validation-observer>
    </b-modal>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      size="sm"
      @click="addData"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>{{ $t('Yeni Stok Girdisi') }}</span>
    </b-button>
    <hr>
    <!-- BODY -->
    <b-table-simple
      hover
      small
      caption-top
      responsive
    >

      <b-thead head-variant="dark">

        <b-tr>
          <b-th>Satıcı Stok Kodu</b-th>
          <b-th>Satıcı</b-th>
          <b-th>Miktar</b-th>
          <b-th>Birim Fiyatı</b-th>
          <b-th>Döviz Kuru</b-th>
          <b-th>Rebate Tutarı</b-th>
          <b-th>Alım Zamanı</b-th>
          <b-th>Açıklama</b-th>
          <b-th>İşlemler</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) in stocksData"
          :id="item.id"
          :key="item.id"
        >

          <b-td>{{ item.seller_stock_code }}</b-td>
          <b-td>{{ item.seller }}</b-td>
          <b-td>{{ item.piece }} {{ item.unit_name }}</b-td>
          <b-td>{{ item.unit_price }} {{ item.currency_name }}</b-td>
          <b-td>{{ item.exchange_rate }} </b-td>
          <b-td>{{ item.rebate_price }} {{ item.currency_name }}</b-td>
          <b-td>{{ $format_datetime(item.buy_date) }}</b-td>
          <b-td>{{ item.description }}</b-td>
          <b-td> <b-button
                   v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                   variant="outline-danger"
                   size="sm"
                   @click="deleteData(index)"
                 >
                   <feather-icon
                     icon="XIcon"
                     class="mr-25"
                   />
                   <span>{{ $t('Sil') }}</span>
                 </b-button>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="primary"
              size="sm"
              @click="editData(item)"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-25"
              />
              <span>{{ $t('Düzenle') }}</span>
            </b-button>
          </b-td>
        </b-tr>

      </b-tbody>

    </b-table-simple>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, VBModal, BFormInput, BButton, BRow, BCol, BInputGroupAppend, BInputGroup, BFormSelect, BModal, BTableSimple, BTr, BTd, BTh, BTbody, BThead,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { VueAutosuggest } from 'vue-autosuggest'
import Cleave from 'vue-cleave-component'
import { ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BForm,
    BInputGroupAppend,
    BInputGroup,

    Cleave,
    VueAutosuggest,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BTbody,
    BThead,
    BModal,
    BFormGroup,
    flatPickr,
    BFormInput,
    BFormSelect,
    BButton,
    ValidationObserver,
    BCol,
    BRow,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isEditCustomerSidebarActive',
    event: 'update:is-edit-customer-sidebar-active',
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    productId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      sellerSuggest: {
        limit: 10,
        filteredProducts: [],
        inputProps: {
          id: 'autosuggest__input',
          class: 'form-control',
          placeholder: 'Satıcı Adı giriniz',

        },

      },
      required,
      alphaNum,
      email,
      showEditModal: false,
      countries,
      selectedStockData: null,
      blankStockData: {
        id: 0,
        product_id: 0,
        seller: '',
        seller_stock_code: '',
        unit_price: 0,
        currency_id: 0,
        piece: 0,
        unit_id: 0,
        exchange_rate: 0,
        list_price: 0,
        rebate_price: 0,
        buy_date: '',
        description: '',
      },
      stocksData: [],
      dataFetched: false,
      priceInputConfig: {
        numeralDecimalScale: 4,
        numeral: true,
      },
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.fetchData()
      } else if (this.dataFetched) {
        this.$emit('dataChanged', this.stocksData)
      }
    },
  },
  mounted() {
    this.resetstoreData()
    this.fetchData()
  },
  methods: {
    sellerSelected(option) {
      this.selectedStockData.seller = option.item.name
    },
    addData() {
      this.resetstoreData()
      this.showEditModal = true
    },
    editData(item) {
      this.selectedStockData = JSON.parse(JSON.stringify(item))
      this.showEditModal = true
    },
    productOnInputChanged(text) {
      if (text === '' || text === undefined) {
        return
      }
      store.dispatch('app-p-product/suggestSeller', { name: text, limit: this.sellerSuggest.limit })
        .then(response => {
          this.sellerSuggest.filteredProducts = [{
            data: response.data.data,
          }]
        })
    },
    deleteData(index) {
      const selectedData = this.stocksData[index]
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu stok girdisini silmek istediğinizden emin misiniz?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-p-product/deleteStockData', { id: selectedData.id })
            .then(() => {
              if (index > -1) {
                this.stocksData.splice(index, 1)
              }
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    saveData() {
      if (this.selectedStockData.id === 0) {
        this.selectedStockData.product_id = this.productId

        store.dispatch('app-p-product/addStockData', this.selectedStockData)
          .then(() => {
            this.fetchData()
            this.resetstoreData()
            this.showEditModal = false
          })
      } else {
        store.dispatch('app-p-product/updateStockData', this.selectedStockData)
          .then(() => {
            this.fetchData()
            this.resetstoreData()
            this.showEditModal = false
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
    addNewData() {

    },
    fetchData() {
      store.dispatch('app-p-product/fetchStocks', { product_id: this.productId })
        .then(response => {
          this.dataFetched = true
          this.stocksData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.blankStockData.value = undefined
          }
        })
    },
    resetstoreData() {
      this.selectedStockData = JSON.parse(JSON.stringify(this.blankStockData))
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const onSubmit = () => {
    }
    const resetstoreData = () => {
      this.selectedStockData.value = JSON.parse(JSON.stringify(this.blankStockData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetstoreData)
    const unitOptions = ref([])
    store.dispatch('app/fetchUnitTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
        unitOptions.value = arr
      })
    const currencyOptions = ref([])
    store.dispatch('app/fetchCurrencyTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
        currencyOptions.value = arr
      })
    return {
      onSubmit,
      currencyOptions,
      priceInputConfig: {
        numeralDecimalScale: 2,
        numeral: true,
      },
      refFormObserver,
      getValidationState,
      resetForm,
      unitOptions,
    }
  },
}
</script>
<style lang="scss">

@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
