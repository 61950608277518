<template>
  <div>

    <!-- stoklar modal -->
    <b-modal
      id="stocks-modal"
      v-model="showProductStocks"
      size="xl"
      hide-footer
      :title="$t('Stok ve satın alım bilgisi')"
    >
      <product-stocks
        :product-id="productData.id"
        :show="showProductStocks"
      /></b-modal>
    <!-- Kategorü düzenleme modal -->
    <b-modal
      id="categories-modal"
      v-model="showCategoriyEditor"
      size="lg"
      hide-footer
      :title="$t('Ürün Kategorileri')"
    >
      <category-list
        :show="showCategoriyEditor"
        @dataChanged="categoryDataChanged"
      /></b-modal>
    <!-- Marka Düzenleme Modal -->
    <b-modal
      id="brands-modal"
      v-model="showBrandEditor"
      size="lg"
      hide-footer
      :title="$t('Ürün Markaları')"
    >
      <brand-list
        :show="showBrandEditor"
        @dataChanged="brandDataChanged"
      /></b-modal>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        v-if="productData"
        class="p-2"
        @submit.prevent="handleSubmit(saveData)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="8">
            <b-tabs
              pills
              nav-class="nav-pill-secondary"
            >
              <b-tab
                :title="$t('Türkçe Ürün Bilgileri')"
                active
              >
                <!-- Taslak mı  -->
                <b-form-group>
                  <b-form-checkbox
                    id="is_draft"
                    v-model="productData.is_active"
                    class="custom-control-primary"
                    name="check-button"
                    :value="1"
                    :unchecked-value="0"
                  >
                    {{ $t('Aktif') }}
                  </b-form-checkbox>

                </b-form-group>
                <!-- Ürün Adı -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Ürün Adı')"
                  rules="required|min:2"
                >
                  <b-form-group
                    :label="$t('Ürün Adı')"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="productData.name"
                      autofocus

                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                  :label="$t('Ürün Açıklaması')"
                  label-for="description"
                >
                  <quill-editor
                    id="description"
                    v-model="productData.description"
                    :options="snowOption"
                  />
                </b-form-group>

              </b-tab>

            </b-tabs>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t('Kaydet') }}
              </b-button>
            </div>

          </b-col>
          <b-col
            md="4"
          >
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                    icon="InfoIcon"
                    size="18"
                  />
                  <span class="align-middle ml-50">{{ $t('Ürün Genel Bilgileri') }}</span>
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <!-- Taslak mı  -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    @click="showProductStocks=true"
                  >
                    {{ $t('Stok bilgisi') }}
                  </b-button>
                </div>
                <!-- Stok Kodu -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Stok Kodu')"
                  rules="required|min:2"
                >
                  <b-form-group
                    :label="$t('Stok Kodu')"
                    label-for="code"
                  >
                    <b-form-input
                      id="name"
                      v-model="productData.stock_code"
                      autofocus

                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- ürün stokları -->
                <validation-provider

                  :name="$t('Stok')"
                  rules="required"
                >

                  <b-row
                    v-for="(item) in productData.stock_data"
                    :id="item.id"
                    :key="item.id"
                    ref="row"
                  >

                    <!-- Item Name -->
                    <b-col md="12">
                      <b-form-group>
                        <b-input-group>
                          <b-form-input
                            id="item-name"
                            v-model="item.store_name"
                            type="text"
                            :placeholder="$t('Depo')"
                            :readonly="true"
                          />
                          <b-input-group-append>
                            <b-form-input
                              id="item-stock"
                              v-model="item.stock"
                              type="number"
                              :placeholder="$t('Stok')"
                            />
                          </b-input-group-append>
                        </b-input-group>

                      </b-form-group>
                    </b-col>

                  </b-row>
                </validation-provider>
                <!-- Fiyat -->

                <!-- Birim -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Marka')"
                  rules="required"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.brands-modal
                      variant="flat-primary"
                      type="button"
                    >
                      <feather-icon icon="EditIcon" />
                      {{ $t('Markaları Düzenle') }}
                    </b-button>
                  </div>

                  <b-form-group
                    :label="$t('Marka')"
                    label-for="brand_id"
                  >

                    <b-form-select
                      v-model="productData.brand_id"
                      :options="brandsData"
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  #default="validationContext"
                  :name="$t('Kagetori')"
                  rules="required"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.categories-modal
                      variant="flat-primary"
                      type="button"
                    >
                      <feather-icon icon="EditIcon" />
                      {{ $t('Kategorileri Düzenle') }}
                    </b-button>
                  </div>

                  <b-form-group
                    :label="$t('Kategori')"
                    label-for="category_id"
                  >

                    <b-form-select
                      v-model="productData.category_id"
                      :options="categoriesData"
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-card-body>
            </b-card>
          </b-col>

        </b-row>
      </b-form>

    </validation-observer>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BTab, BFormCheckbox, BTabs, BModal, VBModal, BInputGroupAppend, BFormSelect, BInputGroup, BCard, BCardBody, BCardHeader, BCardTitle, BFormInput, BFormInvalidFeedback, BRow, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { ref } from '@vue/composition-api'
import categoryList from '../product-categories/CategoryList.vue'
import brandList from '../product-brands/BrandList.vue'
import productStocks from '../ProductStocks.vue'

export default {
  components: {
    BForm,
    BRow,
    BFormCheckbox,
    BTab,
    BTabs,
    productStocks,
    brandList,
    BModal,
    BFormSelect,
    BCardBody,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    quillEditor,
    categoryList,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isEditProductSidebarActive',
    event: 'update:is-edit-product-sidebar-active',
  },
  props: {
    blankProductData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      showTaxTypeEditor: false,
      showCategoriyEditor: false,
      showBrandEditor: false,
      showStoreEditor: false,
      showProductStocks: false,
    }
  },
  watch: {
    showCategoriyEditor(val) {
      if (val === false) {
        this.fetchCategories()
      }
    },
    showBrandEditor(val) {
      if (val === false) {
        this.fetchBrands()
      }
    },
  },
  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    makeproductData() {
      this.productData = JSON.parse(JSON.stringify(this.blankProductData))
    },

    taxTypeDataChanged(taxTypesData) {
      this.taxTypesData = []
      taxTypesData.forEach(val => {
        this.taxTypesData.push({ text: val.name, value: val.id })
      })
    },
    storeDataChanged(taxTypesData) {
      this.taxTypesData = []
      taxTypesData.forEach(val => {
        this.taxTypesData.push({ text: val.name, value: val.id })
      })
    },
    // eslint-disable-next-line no-unused-vars
    categoryDataChanged(categoriesDAta) {
    },
    // eslint-disable-next-line no-unused-vars
    brandDataChanged(brandsData) {
    },
    saveData() {
      store.dispatch('app-p-product/updateProduct', this.productData)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    // fetch user
    const countryOptions = ref(null)
    const managersList = ref([])
    const productData = ref(null)
    const currencyOptions = ref([])
    const unitOptions = ref([])
    const categoriesData = ref([])
    const brandsData = ref([])
    const taxTypesData = ref([])
    store.dispatch('app/fetchTaxTypes', {})
      .then(response => {
        const arr = []

        response.data.data.forEach(val => {
          arr.push({ text: val.name, value: val.id })
        })
        taxTypesData.value = arr
      })
      .catch(error => {
        if (error.response.status === 404) {
          taxTypesData.value = null
        }
      })
    const fetchCategories = () => {
      store.dispatch('app-p-product/fetchCategories', { list_type: 'all' })
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
          categoriesData.value = arr
        })
    }
    fetchCategories()
    const fetchBrands = () => {
      store.dispatch('app-p-product/fetchBrands', { list_type: 'all' })
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
          brandsData.value = arr
        })
    }
    fetchBrands()
    store.dispatch('app/fetchCurrencyTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
        currencyOptions.value = arr
      })
    store.dispatch('app/fetchUnitTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
        unitOptions.value = arr
      })
    const onSubmit = () => {

    }
    const resetproductData = () => {
      productData.value = JSON.parse(JSON.stringify(props.blankProductData))
    }
    resetproductData()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetproductData)

    return {
      onSubmit,
      countryOptions,
      refFormObserver,
      getValidationState,
      currencyOptions,
      categoriesData,
      managersList,
      taxTypesData,
      unitOptions,
      brandsData,
      snowOption: {
        theme: 'snow',
      },
      priceInputConfig: {
        numeralDecimalScale: 4,
        numeral: true,
      },
      productData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
