<template>

  <div>
    <b-modal
      id="edit-modal"
      v-model="showCategoriyEditor"
      hide-footer
      size="lg"
      :title="$t('Ürün Kategorileri')"
    >
      <Brand-edit
        :modal-show="showCategoriyEditor"
        :show="showCategoriyEditor"
        :selected-brand="selectedBrand"
        @dataChanged="BrandDataChanged"
      /></b-modal>
    <!-- Filters -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">

              <b-button
                variant="primary"
                @click="openModal('add')"
              >
                <span class="text-nowrap">{{ $t('Yeni Kategori Ekle') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refBrandtListTable"
        class="position-relative"
        :items="fetchCategories"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(creator)="data">
          <b-badge
            pill
            variant="info"
            class="text-capitalize"
          >
            <b-badge
              v-if="data.item.creator==2"
              variant="info"
              class="badge-round"
            >
              {{ $t('Otomatik Oluşturulan') }}
            </b-badge>
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="openModal('edit',data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Düzenle') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.creator==1"
              variant="danger"
              @click="deleteData(data.item)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">{{ $t('Sil') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Gösterilen') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BModal, VBModal, BBadge, BButton, BTable, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import productStoreModule from '../productStoreModule'
import BrandEdit from './BrandEdit.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BModal,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BrandEdit,

    vSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      showCategoriyEditor: false,
      selectedBrand: null,
      editComponentMode: 'add',
      selectedId: 0,

    }
  },
  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    deleteData(data) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Kategori Silindiğinde Bu Kategorinin Kullanıldığı  Ürün Verileri Bozulabilir.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-p-product/deleteBrand', { id: data.id })
            .then(() => {
              this.$refs.refBrandtListTable.refresh()
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    openModal(mode, data = null) {
      this.editComponentMode = mode
      if (mode === 'edit' && data != null) {
        this.selectedBrand = data
      }
      this.showCategoriyEditor = true
    },
    BrandDataChanged() {
      this.showCategoriyEditor = false
      this.$refs.refBrandtListTable.refresh()
    },
    addIsSuccess(id) {
      this.editProductId = id
      this.isEditProductSidebarActive = true
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-p-product'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()
    // eslint-disable-next-line no-unused-vars
    const editProductId = ref(0)
    const isAddNewProductSidebarActive = ref(false)
    const isEditProductSidebarActive = ref(false)

    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const searchQuery = ref('')
    const totalCategories = ref(0)
    const refBrandtListTable = ref(null)
    const currentPage = ref(1)
    const tableColumns = [
      { key: 'name', sortable: false },
      { key: 'creator', sortable: false },
      { key: 'actions' },
    ]

    const refetchData = () => {
      refBrandtListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refBrandtListTable.value ? refBrandtListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    const fetchCategories = (ctx, callback) => {
      store
        .dispatch('app-p-product/fetchBrands', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          const { data } = response.data.data
          const { total } = response.data.meta
          callback(data)
          totalCategories.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching product list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {

      // Sidebar
      isAddNewProductSidebarActive,
      isEditProductSidebarActive,
      tableColumns,
      // Filter
      avatarText,
      perPageOptions,
      perPage,
      dataMeta,
      fetchCategories,
      searchQuery,
      totalCategories,
      currentPage,
      refetchData,
      // UI

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
